import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { SharedService } from 'src/app/services/service.index';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.css']
})
export class OfertasComponent implements OnInit {
  ofertas: any;


  constructor(public _sharedService: SharedService, private router: Router, @Inject(DOCUMENT) private document, private elementRef: ElementRef) { }
  @Input() searchtext;

  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}

  ngOnInit() {
this.getOfertas();

  }

  getOfertas(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceGetOfertas().subscribe(
       resp => {
         if(resp){
           this.ofertas = resp;
           console.log(resp);
           swal.close();
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay Ofertas", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }



deleteOferta(id: object){
  let idOf ={};
idOf['idOfer'] = id;
console.log(idOf);
        swal({
          title: 'Borrar Oferta',
          text: "¿Esta seguro que quieres borrar la oferta?",
          type: 'question',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then((ret) => {
          if (ret.value) {
            this._sharedService.serviceDeleteOferta(idOf).subscribe(
              resp => {
                if(resp){
                  swal.close();
                  this.reset();
                } else {
                  console.log("Response error: " + resp);
                }
                },
                err => {     
                  swal.close();
                  switch(err.status) { 
                    default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
                  }
                } 
              )
            swal(
              'Oferta borrada',
              'La oferta se ha borrado',
              'success'
            );
          }
       })  
  }
  NewOferta(){
    this.router.navigate(['/newOferta']);
  }

  enviarOferta(id: number){
    this.router.navigate(['/sendOferta/'+ id]);
  }

  reset(){
    window.location.reload();
  }
}
