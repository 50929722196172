import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { forEach } from '@angular/router/src/utils/collection';
import { isNumeric } from 'rxjs/util/isNumeric';
import { parseWebDriverCommand } from 'blocking-proxy/built/lib/webdriver_commands';

@Component({
  selector: 'app-send-ofertas',
  templateUrl: './send-ofertas.component.html',
  styleUrls: ['./send-ofertas.component.css']
})
export class SendOfertasComponent implements OnInit {
  usuarios: any[];
  checkboxes= {};
  fulCheck= [{}];
  ofertas: any;
  idFich: any;
  pedir: any;
  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute, private router: Router, @Inject(DOCUMENT) private document, private elementRef: ElementRef) { 

 activatedRoute.params.subscribe( params => {
      this.idFich = params['id'];
    });

  }
  
  @Input() searchtext;



  
  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}

  ngOnInit() {
    this.getUsuarios();
    this.getOfertas();

    
  }

  getUsuarios(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceUsuariosOfertas().subscribe(

    resp => {
      if(resp){
        this.usuarios = resp;
     /*    this.usuarios.forEach(nombre => this.checkboxes[nombre]=true); */
        swal.close();
      
							const s = this.document.createElement('script');
							s.type = 'text/javascript';
							s.src = 'assets/js/footable-init.js';
							const __this = this; //to store the current instance to call 
												//afterScriptAdded function on onload event of 
												//script.
							s.onload = function () { __this.afterScriptAdded(); };
							this.elementRef.nativeElement.appendChild(s);
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }
  getOfertas(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceGetOfertas().subscribe(
       resp => {
         if(resp){
           this.ofertas = resp;
           swal.close();
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay Ofertas", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }

  enviar(){
       console.log(this.checkboxes);
       console.log(this.idFich);
      swal({
        text: 'Guardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
            this._sharedService.serviceAddOfertaUser_APP(this.idFich,this.checkboxes).subscribe(
              resp => {
                swal.close();
                if(resp){
                swal('Ofertas', 'Oferta Enviada', 'success');
                } else {
                  console.log("Response error: " + resp);
                }
              },
              err => {     
                swal.close();
                //console.log("Server error: " + err);
                switch(err.status) { 
                  default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
                }
              }
            );
          }
        });
  
    }

/* marcar(){

  for (let item in this.checkboxes){

    this.fulCheck.push(item);
    console.log(this.fulCheck);
    
  }


}
desmarcar(){} */

  }


