import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { PagesComponent } from "./pages.component";
import { DashboardComponent } from './dashboard/dashboard.component';

import { NgSelect2Module } from 'ng-select2';

import { PAGES_ROUTES } from "./pages.routes";
import { DashAgenciaComponent } from './dash-agencia/dash-agencia.component';
import { AgenciasComponent } from './dashboard/agencias/agencias.component';
import { GaleriaComponent } from './dashboard/galeria/galeria.component';
import { PlantillasComponent } from './dashboard/plantillas/plantillas.component';
import { CuentaComponent } from './dashboard/cuenta/cuenta.component';
import { ClientesAPPComponent } from './dash-agencia/clientes-app/clientes-app.component';
import { DocumentosComponent } from './dash-agencia/documentos/documentos.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetpassComponent } from './resetpass/resetpass.component';
import { AgenciaComponent } from './dashboard/agencia/agencia.component';
import { NewAgenciaComponent } from './dashboard/new-agencia/new-agencia.component';
import { ClienteAppComponent } from './dash-agencia/cliente-app/cliente-app.component';
import { NewClienteAppComponent } from './dash-agencia/new-cliente-app/new-cliente-app.component';
import { PipesModule } from './pipes/pipes.module';
import { ImagenesComponent } from './dashboard/galeria/imagenes/imagenes.component';
import { ViajesComponent } from './dash-agencia/viajes/viajes.component';
import { UpdateLogoComponent } from './dashboard/agencia/update-logo/update-logo.component';
import { NewViajeComponent } from './dash-agencia/viajes/new-viaje/new-viaje.component';
import { OfertasComponent } from './dash-agencia/ofertas/ofertas.component';
import { NewOfertasComponent } from './dash-agencia/ofertas/new-ofertas/new-ofertas.component';
import { SendOfertasComponent } from './dash-agencia/ofertas/send-ofertas/send-ofertas.component';
import { PantallPrincipalComponent } from './dashboard/pantall-principal/pantall-principal.component';
import { ImagenPrincipalComponent } from './dashboard/pantall-principal/imagen-principal/imagen-principal.component';


@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        DashAgenciaComponent,
        AgenciasComponent,
        GaleriaComponent,
        PlantillasComponent,
        CuentaComponent,
        ClientesAPPComponent,
        DocumentosComponent,
        ResetpassComponent,
        AgenciaComponent,
        NewAgenciaComponent,
        ClienteAppComponent,
        NewClienteAppComponent,
        ImagenesComponent,
        ViajesComponent,
        UpdateLogoComponent,
        NewViajeComponent,
        OfertasComponent,
        NewOfertasComponent,
        SendOfertasComponent,
        PantallPrincipalComponent,
        ImagenPrincipalComponent
    ],
    imports:[
        SharedModule,
        NgSelect2Module,
        PAGES_ROUTES,
        FormsModule,
        ReactiveFormsModule,
        PipesModule
    ],
    exports: [
        DashboardComponent,
    ]
})
export class PagesModule { }