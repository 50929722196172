import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { AuthService } from '../../../services/auth/auth.service';
import { DOCUMENT} from '@angular/platform-browser';
import { consumeBinding } from '@angular/core/src/render3/instructions';

@Component({
  selector: 'app-cliente-app',
  templateUrl: './cliente-app.component.html',
  styleUrls: ['./cliente-app.component.css']
})
export class ClienteAppComponent implements OnInit {

  public usuariosAPP: Object;
  public iduser:number;
  archivos: any[];
  fichero;
  addFichero;
  extension: string;
  name: string;
  size: number;
  viajes: any;
  mail: any ;
  ofertas: any;
  check = false;
  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute,public router: Router,public _authService: AuthService,@Inject(DOCUMENT) private document, private elementRef: ElementRef) { 
    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
  }
  @Input() searchtext;
  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}


  ngOnInit() {
    this.getUsuarioAPP();
    this.getViajes();
 
  }

  getViajes(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();    
    this._sharedService.serviceViajes(this.iduser).subscribe(
       resp => {
         if(resp){
           this.viajes = resp;
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay Viajes", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }
  getUsuarioAPP(){
    let body = {};
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();    
    this._sharedService.serviceUserAPP(this.iduser).subscribe(
    resp => {
      if(resp){
        swal.close();
        this.usuariosAPP = resp[0];
        if(resp[0].notificaciones === '1'){
             this.check =true ;
        }
        let mail = resp[0].mail;
        body['mailUser'] = mail;
        this._sharedService.serviceOfertasEnviadas(body).subscribe(
          resp => {
            if(resp){
              this.ofertas = resp;
                    const s = this.document.createElement('script');
                    s.type = 'text/javascript';
                    s.src = 'assets/js/footable-init.js';
                    const __this = this; //to store the current instance to call 
                              //afterScriptAdded function on onload event of 
                              //script.
                    s.onload = function () { __this.afterScriptAdded(); };
                    this.elementRef.nativeElement.appendChild(s);
            } else {
              console.log("Response error: " + resp);
            }
            },
            err => {     
              swal.close();
              //console.log("Server error: " + err);
              switch(err.status) { 
                case 500: { 
                  swal("no hay Ofertas", "", "warning");
                break;               
                }
                default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
              }
            } 
          );
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }

  setUsuariosAPP(){
    this.usuariosAPP['notificaciones']= this.check;
    swal({
			text: 'Guardando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._sharedService.serviceSetUserAPP(this.usuariosAPP).subscribe(
    resp => {
      if(resp){
        swal.close();
        this.router.navigate(['/clientesAPP']);
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });
  }

  deleteUserAPP(){
    swal({
      title: 'Borrar Usuario-APP',
      text: "¿Esta seguro que quieres borrar el Usuario-APP",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        this._sharedService.serviceDeleteAgencia_UserAPP(this.usuariosAPP).subscribe(
          resp => {
            if(resp){
              swal.close();
              this.router.navigate(['/clientesAPP']);
            } else {
              console.log("Response error: " + resp);
            }
            },
            err => {     
              swal.close();
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
              }
            } 
          )
        swal(
          'Usuario-APP borrado',
          'El Usuario-APP se ha borrado',
          'success'
        );
      }
    })  
  }

  deleteViaje(){
    swal({
      title: 'Borrar Usuario-APP',
      text: "¿Esta seguro que quieres borrar el Usuario-APP",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        this._sharedService.serviceDeleteAgencia_UserAPP(this.usuariosAPP).subscribe(
          resp => {
            if(resp){
              swal.close();
              this.router.navigate(['/clientesAPP']);
            } else {
              console.log("Response error: " + resp);
            }
            },
            err => {     
              swal.close();
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
              }
            } 
          )
        swal(
          'Usuario-APP borrado',
          'El Usuario-APP se ha borrado',
          'success'
        );
      }
    })  
  }

setAddTravel(id:number){

  this.router.navigate(['/newViaje/'+id]);
 
}
  
reset(){
  window.location.reload();
}
goViaje(id:number){
  this.router.navigate(['/viaje/'+id]);

 }

}

