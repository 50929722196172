import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/service.index';
declare function initCustomJS();
@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['../login/login.component.css']
})
export class RecoverComponent implements OnInit {
  mail: string;
  constructor( public router: Router,public _authService: AuthService) { }
  
  ngOnInit() {
    initCustomJS();
  }

  recover(form: NgForm){

    if (!form.valid){
      swal("Recuperar contraseña", "La cuenta no es válida", "warning");
    }else{    

      swal({
        text: 'Recuperando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();  
               
          //SERVICE
          this._authService.serviceRecPass(form.value.mail)
          .subscribe(resp => {
            swal.close();            
            if(resp && resp.Pass.affectedRows == '1' ){
              swal({
                title: 'Contraseña enviada',
                showConfirmButton: false,
                showCloseButton: false,
                showCancelButton: false,                
                timer: 2000,
                type: 'success',
                onClose: () => {
                  this.router.navigate(['/login'])                  
                }
              });              

            }else{
              swal("Recuperar contraseña", "Error al conectar con el servidor", "error");
              console.log("Response error: " + resp);
            }
          },err => {     
            swal.close();
            swal("Recuperar contraseña", "No se pudo recuperar la contraseña", "error");
            console.log("Server error: " + err.status);
          });
          //END SERVICE
          
        }

      });                        
      
    }    
  }

}
