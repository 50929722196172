import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { SharedService, AuthService } from 'src/app/services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import swal from 'sweetalert2';

@Component({
  selector: 'app-viajes',
  templateUrl: './viajes.component.html',
  styleUrls: ['./viajes.component.css']
})
export class ViajesComponent implements OnInit {
  archivos: any;
  iduser: any;
  fichero: File;
  extension: string;
  name: string;
  size: number;
  addFichero: string | ArrayBuffer;
  idViaje: any;
  viajes: any;
  userAPP: any;
  seguros: any;
  plantillas: any;
  plantillasv: any;
  fIni :any;
  fFin :any;

  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute,public router: Router,public _authService: AuthService,@Inject(DOCUMENT) private document, private elementRef: ElementRef) { 
    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
  }
  @Input() searchtext;
  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}


  ngOnInit() {
    this.getDocuments();
    this.getViajes();
    this.getSeguros();
    this.getPlantillas();
    this.getPlantillasViaje();
  }

  getDocuments(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceGetViaje(this.iduser).subscribe(
       resp => {
         if(resp){
           this.archivos = resp;
           swal.close();
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay documentos", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }

     getSeguros(){
      swal({
        text: 'Cargando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
      this._sharedService.serviceGetViajeA(this.iduser).subscribe(
         resp => {
           if(resp){
             this.seguros = resp;
             swal.close();
                   const s = this.document.createElement('script');
                   s.type = 'text/javascript';
                   s.src = 'assets/js/footable-init.js';
                   const __this = this; //to store the current instance to call 
                             //afterScriptAdded function on onload event of 
                             //script.
                   s.onload = function () { __this.afterScriptAdded(); };
                   this.elementRef.nativeElement.appendChild(s);
           } else {
             console.log("Response error: " + resp);
           }
           },
           err => {     
             swal.close();
             //console.log("Server error: " + err);
             switch(err.status) { 
               case 500: { 
                 swal("no hay documentos", "", "warning");
               break;               
               }
               default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
             }
           } 
         );
        }
      });
        }

  getPlantillas(){
          swal({
            text: 'Cargando...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              swal.showLoading();
          this._sharedService.serviceGetViajePlantiAdmin().subscribe(
             resp => {
               if(resp){
                 this.plantillas = resp;
                 swal.close();
                       const s = this.document.createElement('script');
                       s.type = 'text/javascript';
                       s.src = 'assets/js/footable-init.js';
                       const __this = this; //to store the current instance to call 
                                 //afterScriptAdded function on onload event of 
                                 //script.
                       s.onload = function () { __this.afterScriptAdded(); };
                       this.elementRef.nativeElement.appendChild(s);
               } else {
                 console.log("Response error: " + resp);
               }
               },
               err => {     
                 swal.close();
                 //console.log("Server error: " + err);
                 switch(err.status) { 
                   case 500: { 
                     swal("no hay documentos", "", "warning");
                   break;               
                   }
                   default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
                 }
               } 
             );
            }
          });
 }

 getPlantillasViaje(){
  swal({
    text: 'Cargando...',
    allowOutsideClick: false,
    onBeforeOpen: () => {
      swal.showLoading();
  this._sharedService.serviceGetViajePlantillas(this.iduser).subscribe(
     resp => {
       if(resp){
         this.plantillasv = resp;
         swal.close();
               const s = this.document.createElement('script');
               s.type = 'text/javascript';
               s.src = 'assets/js/footable-init.js';
               const __this = this; //to store the current instance to call 
                         //afterScriptAdded function on onload event of 
                         //script.
               s.onload = function () { __this.afterScriptAdded(); };
               this.elementRef.nativeElement.appendChild(s);
       } else {
         console.log("Response error: " + resp);
       }
       },
       err => {     
         swal.close();
         //console.log("Server error: " + err);
         switch(err.status) { 
           case 500: { 
             swal("no hay Plantillas", "", "warning");
           break;               
           }
           default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
         }
       } 
     );
    }
  });
    }
     seleccionArchivo( archivo: File) {
      if ( !archivo ) {
        return;
      }
   if ( archivo.type.indexOf('application/pdf') < 0 ) {
        swal('Sólo Ficheros', 'El archivo seleccionado no es un archivo PDF', 'error');
        return;
      }
  
    this.fichero = archivo;
    let reader = new FileReader();
    reader.readAsDataURL( archivo );
    this.extension = archivo.type;
    this.name = archivo.name;
    this.size =archivo.size/1000;
  
    reader.onloadend = () => this.addFichero = reader.result;
    
  }
    subirFichero(){
      let  body = {};
      body['file'] = this.addFichero;
      body['type'] = this.extension;
      body['name'] = this.name;
      body['size'] = this.size;
      body['idViaje'] = this.iduser;
      swal({
        text: 'Guardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
            this._sharedService.serviceArchivoAdd(body).subscribe(
              resp => {
                swal.close();
                if(resp){
                 this.reset();
                  
                  swal('archivo', 'archivo subido', 'success');
                } else {
                  console.log("Response error: " + resp);
                }
              },
              err => {     
                swal.close();
                //console.log("Server error: " + err);
                switch(err.status) { 
                  default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
                }
              }
            );
          }
        });
  
    }

    subirSeguro(){
      let  body = {};
      body['file'] = this.addFichero;
      body['type'] = this.extension;
      body['name'] = this.name;
      body['size'] = this.size;
      body['idViaje'] = this.iduser;
      swal({
        text: 'Guardando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
            this._sharedService.serviceSeguroAdd(body).subscribe(
              resp => {
                swal.close();
                if(resp){
                 this.reset();
                  
                  swal('archivo', 'archivo subido', 'success');
                } else {
                  console.log("Response error: " + resp);
                }
              },
              err => {     
                swal.close();
                //console.log("Server error: " + err);
                switch(err.status) { 
                  default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
                }
              }
            );
          }
        });
  
    }
    deleteArchivo(id : Number){
      let archivo = {};
      archivo['id'] = id;
      swal({
        title: 'Borrar Archivo',
        text: "¿Esta seguro que quieres borrar el Borrar Archivo:",
        type: 'question',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((ret) => {
        if (ret.value) {
      this._sharedService.serviceDeleteArchivo(archivo).subscribe(
      resp => {
        if(resp){
          this.reset();
          swal('el archivo se ha borrado', '', 'success');
          
          
        } else {
          console.log("Response error: " + resp);
        }
        },
        err => {     
          swal.close();
          //console.log("Server error: " + err);
          switch(err.status) { 
            default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
          }
        }
      )
      swal(
        'Archivo borrado',
        'Archivo se ha borrado',
        'success'
        );
      }
    })  
  }
  reset(){
    window.location.reload();
  }

  
  getViajes(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceGetViajeDetalles(this.iduser).subscribe(
       resp => {
         if(resp){
           this.viajes = resp[0];
           this.userAPP = resp[0].CUSERAPP;
           this.fFin = resp[0].FECHAFIN;
           this.fIni = resp[0].FECHAINI;
           swal.close();
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
              swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error');
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }
  
  setUpdateViaje(){
    this.viajes['idViaje']= this.iduser;
    console.log(this.viajes);
    swal({
			text: 'Guardando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._sharedService.serviceUpdateViajeDetalle(this.viajes).subscribe(
    resp => {
      if(resp){
        swal.close();
       this.reset();
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });
  }
  setDeleteViaje(){
    let idViaje = {};
    idViaje['idViaje'] = this.iduser;
    console.log
    swal({
      title: 'Borrar Viaje',
      text: "¿Esta seguro que quieres borrar el Viaje",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        this._sharedService.serviceDeleteViajes(idViaje).subscribe(
          resp => {
            if(resp){
              swal.close();
              this.router.navigate(['/clienteAPP/'+this.userAPP]);
            } else {
              console.log("Response error: " + resp);
            }
            },
            err => {     
              swal.close();
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
              }
            } 
          )
        swal(
          'Viaje Borrado',
          'success'
        );
      }
    })  

  }

  addPlantilaViaje(plantilla :Object){
    console.log(this.plantillas);
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
  this._sharedService.serviceSetPlantillaViaje(plantilla,this.iduser).subscribe(
        resp => {
          if(resp){
            this.reset();
            swal("plantilla Subida", "correcto", "success"); 
          } else {
            console.log("Response error: " + resp);
          }
          },
          err => {     
            swal.close();
            //console.log("Server error: " + err);
            switch(err.status) { 
              case 500: { 
                swal("no se ha podido subir la imagen", "error", "warning");break; 
              }
              default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
            }
          } 
        );
  }
});
  }
  deletePlantilla(id : Number){
    let archivo = {};
    archivo['id'] = id;
    swal({
      title: 'Borrar Archivo',
      text: "¿Esta seguro que quieres borrar el Borrar Archivo:",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
    this._sharedService.serviceDeletePlantillaViaje(archivo, this.iduser).subscribe(
    resp => {
      if(resp){
        this.reset();
        swal('el archivo se ha borrado', '', 'success');
        
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      }
    )
    swal(
      'Archivo borrado',
      'Archivo se ha borrado',
      'success'
      );
    }
  })  
}

  saveAsArchivo(data: any){
    console.log(data);
    saveAs(new Blob([new Uint8Array(data.BFICHERO.data)]),data.CNOMFICH); //FileSaver.js
    }
}
