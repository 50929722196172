import { Component, Input, Output, ViewChild, OnInit, EventEmitter, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ActivationEnd } from '@angular/router';
import { SharedService, SidebarService } from 'src/app/services/service.index';
import swal from 'sweetalert2';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/filter';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  public expfilter: string;  
  public fullexps:any[];
  public exps:any[];
  public searchword: string;
  subSearchWord: any;
  subReload: any;
  
  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public _sidebarService: SidebarService,
    public _sharedService: SharedService
  ) {
  }

  ngOnInit() {
  }

}
