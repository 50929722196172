import { Component, OnInit } from '@angular/core';
import { SharedService, AuthService } from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {
  cuenta: Object;

  constructor(public _sharedService: SharedService,private router: Router) { }

  ngOnInit() {
    this.getUsuario();
  }


getUsuario(){
  swal({
    text: 'Cargando...',
    allowOutsideClick: false,
    onBeforeOpen: () => {
      swal.showLoading();
  this._sharedService.serviceCuenta().subscribe(
    resp => {
      if(resp){
        swal.close();
        this.cuenta = resp[0];
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => { 
        swal.close();
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );

  }
});
  }

  reset(){
    window.location.reload();
  }
   

  }


