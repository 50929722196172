import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import swal from 'sweetalert2';
import { SharedService } from 'src/app/services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-update-logo',
  templateUrl: './update-logo.component.html',
  styleUrls: ['./update-logo.component.css']
})
export class UpdateLogoComponent implements OnInit {

  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  extension;
  name;
  size;
  iduser: any;
  code: any;


  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute,public router: Router,@Inject(DOCUMENT) private document, private elementRef: ElementRef, private sanitizer: DomSanitizer) {
    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
   }


  ngOnInit() {
/*   this.getLogo(); */
  }
  seleccionImage( archivo: File) {

    if ( !archivo ) {
      this.imagenSubir = null;
      return;
    }
 if ( archivo.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

  this.imagenSubir = archivo;
  let reader = new FileReader();
  reader.readAsDataURL( archivo );
  this.extension = archivo.type;
  this.name = archivo.name;
  this.size =archivo.size/1000;

  reader.onloadend = () => this.imagenTemp = reader.result;
  
}
  subirImagen(){
    let  body = {};
    body['file'] = this.imagenTemp;
    body['type'] = this.extension;
    body['namef'] = this.name;
    body['size'] = this.size;
    body['CAGENCIA'] = this.iduser;
    console.log(body);
    swal({
      text: 'Guardando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
          this._sharedService.serviceUpdateLogo(body).subscribe(
            resp => {
              swal.close();
              if(resp){
                this.router.navigate(['/agencias']);
                swal('Imagen', 'imagen subida', 'success');
              } else {
                console.log("Response error: " + resp);
              }
            },
            err => {     
              swal.close();
              //console.log("Server error: " + err);
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
              }
            }
          );
        }
      });

  }
/*   getLogo(){
    swal({
			text: 'Cargando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._sharedService.serviceLogo(this.iduser).subscribe(

    resp => {
      if(resp){
        swal.close();
        	
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });
  } */
}