import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
//@Injectable()
export class AuthGuard implements CanActivate {

  constructor( 
    public router: Router,
    public _authService: AuthService
  ) { }

  canActivate(){
    console.log("AuthGuard ready!");
    if (this._authService.serviceIsLogged()){
      return true;
    }else{
      this.router.navigate(['/login']);
      return false;
    }
    
  }
}
