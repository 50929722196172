import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/service.index';
import swal from 'sweetalert2';
declare function initCustomJS();
@Component({
  selector: 'app-lopd',
  templateUrl: './lopd.component.html',
  styleUrls: ['./lopd.component.css']
})
export class LOPDComponent implements OnInit {
  mail: string;
  nombres: any[] = [
    "CONDICIONES GENERALES DE DISTRIBUCIÓN DE SEGUROS MEDIADOS MEDIA2 CONSULTING SL. CORREDURÍA DE SEGUROS APLICABLES A LOS COLABORADORES EXTERNOS",
    "1. OBJETO",
    "1.1. El objeto de estas Condiciones Generales de Distribución de Seguros mediados por Media2 Consulting S.L que son de aplicación a los Colaboradores Externos (en adelante, las Condiciones Generales) es establecer las condiciones operativas y económicas bajo las cuales la Agencia de Viajes (en adelante, la Agencia o el Colaborador Externo) deberá distribuir los productos de seguro mediados por Media2 Consulting S.L (en adelante, Media2- Surevia), entre los clientes de la Agencia (en adelante, el cliente o los clientes).",
    "1.2. La Agencia está integrada en una asociación de agencias, generalmente denominada Grupo de Gestión, que actúa como Colaborador Externo de Media2 Consulting S.L, a los efectos de lo previsto en el artículo 8 de la Ley 26/2006 de 17 de Julio de Mediación de Seguros y Reaseguros Privados (B.O.E. de 18 de Julio de 2006).",
    "1.3. La Agencia podrá distribuir todos los seguros a los que le autorice la Correduría (turismo, vida, hogar, automóvil, defensa jurídica, comercio, etc) y, en especial todos los productos de asistencia en viaje específicos para las agencias.",
    "2. NUESTRA OPERATIVA DE CONTRATACIÓN DE LOS SEGUROS",
    "2.1. Media2 pone a disposición de la Agencia los soportes informáticos necesarios para el normal desarrollo de la actividad (multitarificador, web de Surevia.es, APP, CRM y todas cuantas herramientas Media2 pueda desarrollar para la comercialización y asesoramiento de los clientes)",
    "2.2. Media2 correrá con el coste del desarrollo e instalación de las herramientas a las Agencias. Estas herramientas serán puestas a disposición del cliente por parte de la Agencia, en el uso y contratación de los seguros. Al mismo tiempo, la Agencia se compromete a facilitar a Media2 el acceso a sus instalaciones para la instalación de las herramientas informáticas. No obstante, las integraciones XML correrán a cargo de la Agencia.",
    "2.3. La Agencia, con el uso de las herramientas informáticas, prestará servicio de asistencia y apoyo en la contratación de los seguros por parte de sus clientes, siempre y cuando ésta, previamente, haya aceptado estas Condiciones Generales.",
    "2.4. La Agencia tiene la obligación de informar al cliente que el asesoramiento está basado en un análisis objetivo de las diferentes alternativas de contrato de seguro, y que Media2, tras un análisis exhaustivo de los diferentes contratos aseguradores de las diferentes compañías, ha seleccionado como los más adecuados. La Agencia se compromete a informar al cliente sobre las diferentes normas de contratación de las mismas. Para ello, la herramienta informática facilitará la contratación de la mejor alternativa. Todo ello conforme a lo indicado en la cláusula 2.10.",
    "2.5. La Agencia informará al cliente sobre los detalles referentes al pago de la prima, la emisión de los recibos, la emisión de la póliza y su entrega. La información podrá ser transmitida de forma verbal, por escrito físico o de forma digital.",
    "2.6. La Agencia tiene expresamente prohibido el estudio de los riesgos y el asesoramiento sobre los contratos de seguro, siendo dicha obligación en exclusiva para Media2. En sus funciones, la agencia se limitará a la recogida de los datos en la herramienta informática, el envío telemático de los datos y la entrega del Certificado de Seguro. Siendo esta la operativa para la contratación de los seguros del Ramo de Turismo.",
    "2.7. La Agencia ofrecerá al comprador del paquete turístico la posibilidad de contratar uno de los seguros del Ramo de Turismo.",
    "2.8. La Agencia deberá rellenar los datos básicos del viaje contratado por el cliente y, tras una búsqueda entre los múltiples productos que Media2 pone a su disposición, obtendrá la oferta de producto adecuada al cliente, entre los cuales seleccionará el más adecuado a la tipología del viaje.",
    "2.9. La Agencia tiene a su disposición un teléfono de atención para consultas e incidencias así como para solventar cualquier duda que le pudiera surgir en relación con la contratación, las garantías de la póliza y cualquier otra duda de producto, precio, etc. Así mismo tendrá a su disposición manuales, tutoriales, videos y todo el material didáctico que la Correduría haya facilitado.",
    "2.10. La Agencia tendrá que recabar de manera proactiva la cesión de datos a favor de la Compañía y de la Correduría, incluyendo la misma a través del medio que la Correduría le indique. Una vez que el cliente acepte la oferta del seguro y se proceda a la contratación de la póliza, la Agencia seguirá los pasos que indica la aplicación para la descarga del certificado de póliza, que la Agencia deberá entregar al tomador junto con las Condiciones Generales de la póliza, formalizando la misma y adjuntando copia de la misma a la Correduría.",
    "2.11. Media2 facilitará a la Agencia una clave de usuario y una contraseña para que esta acceda a las diferentes herramientas informáticas, incluida la plataforma web, y que la Agencia pueda gestionar la contratación del seguro con el cliente. En dicha herramienta, la Agencia tendrá los datos de sus clientes, histórico, situación de las contrataciones y todo aquello que se considere relevante para la buena gestión comercial del seguro, siendo la agencia responsable de la privacidad y uso de las mismas. La Agencia dispondrá de un número de teléfono para consultas e incidencias que puedan surgir con el uso de la aplicación.",
    "2.12. Media2, tras recibir los datos obtenidos y realizar el previo análisis objetivo en el mercado asegurador al que se refiere el artículo 42 de la Ley 26/2006 de 17 de Julio de Mediación de Seguros y Reaseguros Privados, remitirá a la Agencia, a través de la plataforma web, la propuesta de cobertura que mejor se adapte a las necesidades del cliente.",
    "2.13. La Agencia pondrá en conocimiento del cliente la oferta de seguro, facilitándole el contacto con Media2, a través de medios telemáticos, para que puedan realizar cualquier consulta relativa a la oferta de seguro y en su caso su contratación.",
    "2.14. Una vez que el cliente acepte la contratación del seguro, y la Agencia haya seguido los pasos indicados por la plataforma, la contratación finaliza con el envío, por parte de Media2, del certificado de póliza a la Agencia a través de la plataforma web habilitada, para la entrega al cliente. La Agencia deberá dejar constancia expresa de la entrega, debiendo, además, recabar, en cada caso, el consentimiento de su cliente tanto para la contratación del seguro, como para la cesión de sus datos personales a Media2 y a la Aseguradora. El certificado de póliza deberá ser enviado por la Agencia a Media2 por el medio que esta considere oportuno en cada caso.",
    
    "3. CONDICIONES ECONÓMICAS",
    "3.1. En relación con los seguros del Ramo Turismo (ya sean los indicados en la cláusula 1.3. de las presentes Condiciones Generales, o cualquier otro que, en su caso, indicara Media2), la Agencia deberá cobrar la prima al tomador en el momento en el que éste contrate la póliza. La prima cobrada deberá ser la fijada en la aplicación informática para cada tipo de producto de seguro a distribuir por la Agencia.",
    "3.2. En relación con los seguros de otros ramos (ya sean los indicados en la cláusula 1.3. de las presentes Condiciones Generales, o cualquier otro que, en su caso, indicara Media2), Media2 se reserva la gestión del cobro de las primas mediante domiciliación bancaria, estando expresamente prohibido el cobro de las mismas en nombre propio de la Agencia. El incumplimiento de esta prohibición, con independencia de la indemnización de los daños y perjuicios que se originen, conllevará a la resolución de las presentes Condiciones Generales, conforme a lo establecido en la cláusula Nueve de las mismas.",
    "3.3. La Agencia, gestione o no el cobro de la prima, tendrá derecho a percibir una comisión por cada venta realizada con su intermediación siempre y cuando siga y acepte la operativa y directrices establecidas en las presentes Condiciones Generales y/o las fijadas por Media2 en cada momento. La comisión de la Agencia se devengará en el momento en que el cliente tomador de la póliza, en cuya contratación haya intervenido la Agencia, pague, tanto en el caso de seguros del Ramo Turismo como en el resto de ramos, la prima correspondiente, ya sea única o periódica. En el caso de que la Agencia gestione el cobro de la prima, la Agencia detraerá de la misma su comisión. En los supuestos en que la Agencia no gestione el cobro de la prima, por ser gestionado éste por Media2, esta última abonará a la Agencia la comisión que proceda. Media2 pagará las comisiones de manera mensual y emitirá la factura correspondiente a la Agencia; por este motivo, la Agencia autoriza a Media2, a expedir las facturas correspondientes a tal efecto.",
    "El pago de las comisiones se efectuará por períodos mensuales.",
    "Mediante la firma de las presentes Condiciones Generales, la Agencia autoriza expresamente a Media2, como destinatario del servicio, a expedir las facturas correspondientes a las operaciones en cuestión, en virtud de la posibilidad establecida en el artículo 5 del Real Decreto 1496/2003, de 28 de noviembre, por el que se aprueba el Reglamento por el que se regulan las obligaciones de facturación. La autorización permanecerá en vigor durante toda la vigencia de las presentes Condiciones Generales, salvo revocación de la misma por parte de la Agencia.",
    "3.4. Sobre las comisiones que percibirá la Agencia, por la contratación de los seguros del Ramo de Turismo indicados en la cláusula 1.3. de las presentes Condiciones Generales, como de cualquier otro seguro del mismo ramo que Media2 pudiera indicar, la comisión a recibir por la Agencia se calculará sobre la prima neta antes de impuestos, dependiendo del producto y compañía de seguros que la Agencia seleccione para contratar. La tabla de comisiones estará reflejada en la página web en el apartado Comisiones. El devengo del derecho de cobro de comisiones por parte de la Agencia quedará supeditado en todo caso a que el importe de la comisión percibida por Media2 sea superior al importe de la comisión a percibir por la Agencia. En consecuencia, la Agencia no devengará ningún tipo de comisión en el caso de que su comisión sea superior a la comisión de Media2. Sin perjuicio de lo anterior, en el caso de que durante la vigencia del Contrato se redujera, por la circunstancia que fuera, la comisión de mediación que percibe Media2 de las compañías aseguradoras por la mediación en las pólizas intermediadas al amparo del Contrato con respecto a la que recibe a la firma del presente documento, Media2 notificará a la Agencia tal circunstancia, pudiendo modificar unilateralmente las comisiones y notificará las nuevas comisiones a percibir por la Agencia, que serán de aplicación desde la fecha de tal comunicación o desde la fecha que se indique. Con respecto a las comisiones de otros ramos de seguro diferentes a los de turismo e indicados en la cláusula",
    "1.3. de las presentes Condiciones Generales (turismo, vida, hogar, automóvil, defensa jurídica, comercio, etc), así como cualquier otro seguro incluido en los precitados ramos o cualquier otro que, en su caso, Media2 pudiera sugerir, la comisión de la Agencia será del 50% sobre la comisión que perciba Media2 de la aseguradora en la primera anualidad de la póliza, y del 50% sobre la comisión que perciba Media2 de la aseguradora a partir de la segunda anualidad y sucesivas de la póliza.",
    "3.5. Una vez cobrada la comisión - y siempre que el cobro haya sido realizado por la Agencia-, la Agencia liquidará el saldo restante al Grupo de Gestión o Asociación en el que la Agencia se encuentre integrada. El periodo de pago será mensual, quién a su vez liquidará a Media2 el saldo resultante tras detraer su propia comisión.",
    "3.6. La Agencia no descontará comisiones a su favor si el tomador de la póliza no hubiese pagado la correspondiente prima. Al mismo tiempo, en el supuesto de extorno de prima por parte de la entidad aseguradora al cliente en una póliza de seguro que implique la devolución de la comisión percibida por Media2, la Agencia vendrá obligada a devolver igualmente el porcentaje de comisiones que, en su caso, hubiera percibido en esa misma póliza, o bien dicho porcentaje podrá ser compensado por Media2 en posteriores liquidaciones de comisiones que se produjeran.",
    "3.7. En el caso de modificación de las bases técnicas o tarifas de las Entidades Aseguradoras con las que tiene suscrito contrato de mediación Media2, así como cuando existan circunstancias objetivas del mercado, Media2 podrá modificar la presente cláusula Tercera.",
    "3.8. La Agencia carece de derecho de cartera, sin perjuicio del derecho a cobrar las comisiones y percibir los incentivos referidos en la presente cláusula Tercera, siempre y cuando estén en vigor las presentes Condiciones Generales. Una vez canceladas las mismas, cesarán todos los derechos económicos de la Agencia, incluidas las comisiones devengadas con anterioridad a la precitada cancelación.",
    "3.9. La retribución de la Agencia se compondrá de las comisiones e incentivos a que hace referencia la presente cláusula Tercera y constituye la única forma de retribución de la Agencia por todos los conceptos. Cualquier gasto de cualquier índole en los que incurra la Agencia en virtud de estas Condiciones Generales serán a cargo exclusivo de la Agencia sin que tenga derecho a reclamar o repercutir a Media2 alguna otra cantidad.",
    
    "4. OBLIGACIONES DE LAS PARTES DE LA AGENCIA",
    "4.1. Promover contratos de seguros mediados por Media2 entre los clientes de la Agencia, con las condiciones que establezca Media2 en cada momento.",
    "4.2. Utilizar las herramientas informáticas, a su propio coste, que ponga a su disposición Media2, de conformidad con las presentes condiciones, con la máxima diligencia y siguiendo en todo momento las directrices de Media2, a los efectos de mejorar la contratación de pólizas entre los clientes y las compañías aseguradoras.",
    "4.3. Deberá supervisar la puesta en marcha y funcionamiento de las Herramientas informáticas que Media2 pone a su disposición como herramientas de contratación y de gestión de los seguros. Al mismo tiempo deberá tener la diligencia suficiente para el buen funcionamiento de los mismos, notificando al Grupo de Gestión o a Media2, los errores que puedan ser detectados en el uso de la misma y que puedan afectar a la buena consecución de las operaciones a través de dichas Herramientas.",
    "4.4. Deberá comunicar inmediatamente a Media2 cualquier tipo de incidencia que pueda tener lugar en relación con las claves personales que le hayan sido facilitadas a ésta y los usuarios designados (pérdida, olvido, sustracción, revelación a terceros etc.) a fin de que Media2 tome las medidas oportunas.",
    "4.5. Será responsable frente a Media2 de la custodia de las claves personales facilitadas a sus usuarios, así como de todos los daños y perjuicios que puedan derivarse de su uso, pérdida o revelación a terceras personas no autorizadas.",
    "4.6. Velará porque toda la información que se introduzca en las herramientas informáticas de Media2 para la cotización o contratación de una póliza de seguro sea veraz, completa y sea confirmada expresamente por el cliente, siendo la Agencia la única responsable de tales actuaciones.",
    "4.7. Deberá ajustarse en todo momento a la forma de actuación recomendada por Media2, en relación con su política y directrices de promoción de seguros.",
    "4.8. Cumplir con las obligaciones que, en su condición de Colaborador Externo, le vengan impuestas por la legislación vigente en cada momento.",
    "4.9. Destacar en toda la publicidad y documentación de giro y tráfico utilizada para comercializar los productos de seguro en cuestión, que actúa como Colaborador Externo de Media2 Consulting S.L., entidad inscrita en la D.G.S. con la clave J-3120, y provista de capacidad financiera y seguro de Responsabilidad Civil Profesional conforme con lo dispuesto en la Ley 26/2006 de 17 de Julio de Mediación de Seguros y Reaseguros Privados.",
    "4.10. Comunicar a Media2 toda la información a la que pueda tener acceso sobre competidores, servicios, situación y actuación de los mismos.",
    "4.11. Custodiar, digitalizar e indexar los certificados de póliza en la Herramienta informática que Media2 le proporcione durante toda la vigencia de las presentes Condiciones Generales, a disposición de Media2, a requerimiento de Media2 deberá facilitar el mismo en el plazo máximo de 2 días. Del mismo modo, una vez rescindidas, en su caso, las Condiciones Generales presentes, la Agencia deberá devolver todos los certificados a Media2, junto con toda la información del seguro que obre en su poder.",
    "4.12. Se abstendrá de las siguientes situaciones:",
    "4.12.1. Utilizar cualquier material para promover la contratación del producto de seguro que no se encuentre incluido en las herramientas informáticas de Media2 ;",
    "4.12.2. Ofrecer a los asegurados derechos o prestaciones diferentes de los estipulados en los productos de seguro acordados por las partes y contenidos en las herramientas informáticas;",
    "4.12.3. Realizar declaraciones o manifestaciones inexactas o incorrectas sobre el alcance y coberturas de los productos de seguro;",
    "4.12.4. Introducir cambios en los productos de seguro que no hayan sido previamente autorizados, por escrito, por Media2 o la aseguradora;",
    "4.12.5. Ocultar o no informar a los clientes/asegurados del precio del producto del seguro así como de cualesquiera otros costes de administración de forma transparente y respetando, en todo caso, la normativa de seguros y los derechos de los consumidores.",
    "4.13. Disponer de los consentimientos oportunos de los titulares de los datos, de conformidad con la legislación española de protección de datos vigente.",
    
    "DE MEDIA2",
    "4.14. Media2 cumplirá con las obligaciones de formación que vienen impuestas a los Colaboradores Externos por la Ley de Mediación antes referida y, particularmente, con lo establecido en la resolución de 28 de julio de 2006 de la Dirección General de Seguros y Fondos de Pensiones (BOE 15 de agosto de 2006), incluyendo las obligaciones de formación continua previstas en la misma y cualquier otra que pudiera establecerse en ulteriores textos normativos.",
    "4.15. Media2 no será responsable frente a la Agencia ni ningún tercero si las herramientas informáticas puestas a disposición de la Agencia para la distribución de seguros dejaran de funcionar por cualquier causa. En cualquier caso, Media2 queda expresamente facultada para retirar el acceso a dichas herramientas informáticas, en cualquier momento, y sin necesidad de preaviso alguno. Tal actuación no generará derecho a compensación de clase alguna a favor de la Agencia.",
    
    "5. OBLIGACIONES FISCALES Y LABORALES",
    "La Agencia garantizará que está al corriente en sus obligaciones fiscales, laborales y de cualquier otra índole a las que venga legalmente obligada conforme a la legislación vigente en cada momento, haciéndose expresamente responsable de cualquier contingencia que pudiera afectar directa o indirectamente a Media2 como consecuencia de la infracción de dichas obligaciones.",
    
    "6. PROTECCIÓN DE DATOS",
    "6.1. El representante de la Agencia presta su consentimiento para que sus datos personales a los que pudiera tener acceso Media2, así como aquéllos necesarios para la ejecución de las presentes Condiciones Generales, puedan ser incluidos en un fichero automatizado de datos titularidad de Media2. La finalidad de dicho fichero es la gestión y mantenimiento de la relación entre la Agencia y Media2. El representante de la Agencia podrá en cualquier momento acceder a dicho fichero con la finalidad de ejercitar los derechos de acceso, rectificación, cancelación y oposición respecto a sus datos personales.",
    "6.2. La Agencia se compromete a mantener la más absoluta confidencialidad respecto a cualquier tipo de información que pueda recibir de Media2, o de sus clientes. Tal información no podrá ser comunicada a terceros sin el previo consentimiento por escrito de Media2. Asimismo, la Agencia deberá entregar a Media2, en el momento de cancelación de la relación surgida de las presentes Condiciones Generales, o cuando ésta así se lo requiera, toda la documentación, información o material de cualquier tipo que Media2 haya puesto a su disposición.",
    "6.3. De conformidad con lo establecido en la Ley Orgánica 15/1999 de Protección de Datos Personales, la Agencia adquiere los siguientes compromisos:",
    "6.3.1. Utilizar los datos de carácter personal a los que tenga acceso única y exclusivamente para cumplir con sus obligaciones para con Media2 y siguiendo sus indicaciones.",
    "6.3.2. Observar y adoptar cuantas medidas de seguridad sean necesarias para asegurar la confidencialidad, secreto e integridad de los datos de carácter personal a los que tenga acceso, teniendo en cuenta el grado de seguridad que los datos en cuestión requieran.",
    "6.3.3. No ceder, en ningún caso, a terceras personas los datos de carácter personal a los que tenga acceso en virtud de la relación surgida de las presentes Condiciones Generales, ni tan siquiera a efectos de su conservación, salvo instrucciones de Media2.",
    "6.3.4. Destruir los datos a los que tenga acceso por cuenta de Media2 una vez finalizada la relación entre Media2 y la Agencia; emitiendo la oportuna confirmación escrita de tal circunstancia.",
    "6.4. Se prohíbe expresamente la posibilidad de subcontratar los servicios objeto de las presentes Condiciones Generales.",
    "6.5. En todos los contactos con sus clientes, la Agencia de viajes deberá informar de los productos de seguro y, en caso de que el cliente decida contratar cualquier producto contenido en las herramientas informáticas de Media2, recabar, en todos los casos, el consentimiento expreso e inequívoco del afectado para el tratamiento de sus datos personales por parte de Media2, así como el consentimiento para la contratación del producto de seguro distribuido por Media2, debiendo la Agencia custodiar en soporte duradero los consentimientos otorgados por los clientes caso por caso.",
    "6.6. Las obligaciones derivadas de la presente cláusula Sexta tienen carácter indefinido y, por tanto, permanecerán en pleno vigor aún extinguida la relación entre las partes, con independencia de la causa que la motive.",
    "6.7. El incumplimiento de la presente cláusula facultará a Media2 a resolver la relación entre las partes regulada por las presentes Condiciones Generales, sin perjuicio de iniciar las acciones legales por los daños y perjuicios que se pudieran causar.",
    
    "7. RESPONSABILIDAD",
    "Cuando por acción u omisión de la Agencia exista culpa o negligencia, incluso leve, así como por el incumplimiento o defectuoso cumplimiento de las Condiciones Generales o de normas reguladoras de la mediación en seguros por su parte, resultase Media2 responsable de Derecho y obligada a reparar el daño causado tanto a aseguradores, como a agencias de viajes, asegurados o terceros, o se le impusieran sanciones de cualquier género, la Agencia estará obligada, —a primer requerimiento— a mantener indemne e indemnizar a Media2 de dichas reclamaciones, responsabilidades, procedimientos, costes, daños directos e indirectos, multas, pérdidas o gastos (incluyendo los costes legales razonables) en los que incurra Media2.",
    
    "8. DURACIÓN",
    "Las presentes Condiciones Generales mantendrán su vigencia en tanto en cuanto la Agencia actúe como Colaborador Externo de Media2 de conformidad con las presentes Condiciones Generales.",
    
    "9. RESOLUCIÓN",
    "9.1 Podrán ser causa de resolución de la relación entre las partes, las siguientes:",
    "9.1.1 Por declaración concursal de la Agencia que impida el cumplimiento de sus obligaciones establecidas en las Condiciones Generales.",
    "9.1.2 Por disolución de la Agencia o de Media2.",
    "9.1.3 Por cualquier causa que, con arreglo a Derecho, conlleve el cese en el ejercicio de la profesión de la Agencia.",
    "9.1.4 Por incumplimiento de las obligaciones derivadas de las presentes Condiciones Generales o de la legislación relativa a seguros.",
    "9.1.5 Por la compra de la Agencia, total o parcialmente, por parte de un competidor de Media2, así como por la situación prevista en la cláusula Decimotercera.",
    "9.1.6 Por voluntad unilateral de Media2 en cualquier momento, exigiéndose como único requisito para llevar a efecto dicha decisión, la notificación a la Agencia en tal sentido con al menos quince (15) días de antelación a la fecha efectiva de cancelación de las Condiciones Generales.",
    "9.2 Cuando se extinga la relación entre las partes por cualquiera de las causas contempladas en los apartados precedentes, excepto el apartado 9.1.6., deberá mediar un plazo de preaviso de al menos diez (10) días.",
    
    "10. NOTIFICACIONES",
    "Todas las notificaciones entre las partes o demás comunicaciones requeridas en virtud de los términos de las presentes Condiciones Generales, deberán hacerse por escrito y ser enviadas por correo certificado con acuse de recibo o por cualquier otro medio que permita acreditar su recepción, a las direcciones que Media2 y la Agencia se indiquen.",
    
    "11. INTEGRIDAD DE LOS ACUERDOS",
    "11.1. Todas las obligaciones derivadas de las presentes Condiciones Generales quedan recogidas en las cláusulas del presente documento.",
    "11.2. Si alguna de las cláusulas de las presentes Condiciones Generales fuese declarada nula por resolución dictada por un Organismo Jurisdiccional competente, las restantes cláusulas conservarían toda su eficacia.",
    "11.3. En el caso de que una de las partes tolere actuaciones de la otra que pudieran ser contrarias a lo establecido en las cláusulas de las Condiciones Generales, dicha tolerancia no llevará implícita la renuncia a exigir en cualquier momento el cumplimiento de las obligaciones y derechos establecidos en las presentes Condiciones Generales.",
    
    "12. INCOMPATIBILIDADES",
    "La Agencia garantiza que no incurre en ninguna causa de incompatibilidad y /o restricción legal de clase alguna que le impida la normal ejecución de las obligaciones derivadas de las presentes Condiciones Generales, garantizando tal circunstancia durante toda la vigencia de las mismas. En particular, la Agencia garantiza que no es agente de seguros, ya sea exclusivo o vinculado, operador de banca seguros y/o cualquier otra figura o cargo que fuese incompatible o le impidiese actuar como Colaborador Externo” de Media2.",
    
    "13. LEGISLACIÓN APLICABLE Y JURISDICCIÓN",
    "Las presentes Condiciones Generales se sujetan a la ley española. Las partes acuerdan que todo litigio, discrepancia, cuestión o reclamación resultantes de la ejecución o interpretación de las Condiciones Generales relacionado con las mismas, directa o indirectamente, se someta a la jurisdicción de los tribunales de la Ciudad de Valladolid."
    
    ];

  constructor(public router: Router,  public _authService: AuthService) { }

  ngOnInit() {
  initCustomJS();
  this.mail = localStorage.getItem('user') || '';
  }

  getLogin(){
    this._authService.serviceLogout();
    this.router.navigate(['/login'])  
    
  }

  setFirmar(){
    let  body = {};
    body['mail'] = this.mail;
   swal({
			text: 'Firmando',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._authService.serviceLOPD(body).subscribe(
    resp => {
      if(resp){
        swal.close();
        this.router.navigate(['/dashboard-agencia'])
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });


  }
}
