import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-new-viaje',
  templateUrl: './new-viaje.component.html',
  styleUrls: ['./new-viaje.component.css']
})
export class NewViajeComponent implements OnInit {
  public viaje: Object;
  public iduser:number;
  usuariosAPP: any;
  mail: any;


  constructor(public _sharedService: SharedService,public router: Router,public activatedRoute: ActivatedRoute,) {
    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
   }

  ngOnInit() {
    this.initViaje();
    this.getUsuarioAPP();
  }

  setAddViaje(){
   this.viaje['CUSERAPP'] = this.iduser;
    this.viaje['USER_LOGIN'] = this.mail;
  this._sharedService.serviceAddViaje(this.viaje).subscribe(
    resp => {
      swal.close();
      if(resp){
        this.router.navigate(['/clienteAPP/'+this.iduser]);
        swal("Viaje creado correctamente", " ", "success");
        
      } else {
        swal("FALLO", " ", "error");
        console.log("Response error: " + resp);
      
      }
  },
  err => {     
    swal.close();
    switch(err.status) {
      case 401: swal({title:"Fallo Interno en el Servidor", text:"Fallo Interno en el Servidor", type:"error"})
      break;
      case 500: swal({title:"Fallo Interno en el Servidor", text:"Fallo Interno en el Servidor", type:"error"})
      break;
      case 404: swal({title:"Fallo Interno en el Servidor", text:"Fallo Interno en el Servidor", type:"error"})
      break;
      default: swal({title:"Fallo Interno en el Servidor", text:"Fallo Interno en el Servidor", type:"error"})
      break;
    }
    });
}
  

  initViaje(){

    this.viaje = {
      code: '', 
      name:'',
      destino:'', 
			Fini:'',
      Ffi: '',
      notas:'',
      tel1:'',
      tel2:'',
      correo:'',
      web:'',
    };
  }
  getUsuarioAPP(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceUserAPP(this.iduser).subscribe(

    resp => {
      if(resp){
        this.usuariosAPP = resp[0];
        this.mail = resp[0].mail;
        swal.close();
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }
  reset(){
    window.location.reload();
  }

}
