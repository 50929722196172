import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/service.index';

@Component({
  selector: 'app-new-agencia',
  templateUrl: './new-agencia.component.html',
  styleUrls: ['./new-agencia.component.css']
})
export class NewAgenciaComponent implements OnInit {
	public agencia: Object;
	imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  extension;
  defecto = "../../../../assets/images/logo-fondo.jpg";
  name;
  size;
  sinImagen = false;
  constructor(public router: Router, public _sharedService: SharedService,) { }

  ngOnInit() {
    this.initAgencia();
  }

  initAgencia(){

    this.agencia = {
      mail: '', 
      name:'',
      empresa:'', 
      cif:'', 
			direccion:'',
			personadecontacto: '',
      codeAgencia:'',
      grupoGestion:'',
			web:'',
      poblacion:'', 
      cp:'', 
      provincia:'', 
      telefono:'',
    };
  }

  addAgencia(){  
		this.agencia['file'] = this.imagenTemp;
    this.agencia['type'] = this.extension;
    this.agencia['namef'] = this.name;
		this.agencia['size'] = this.size;
		console.log(this.agencia);
swal({
			text: 'Guardando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
					this._sharedService.serviceAddAgencia(this.agencia).subscribe(
						resp => {
							console.log(this.agencia);
							swal.close();
							if(resp){
								this.router.navigate(['/agencias']);
							} else {
								console.log("Response error: " + resp);
							}
						},
						err => {     
							swal.close();
							//console.log("Server error: " + err);
							switch(err.status) { 
								case 401: swal('Mail Existente', 'El mail ya está registrado en la plataforma.', 'error'); break;
								default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
							}
						}
					);
				}
			});
}
	seleccionImage( archivo: File) {

    if ( !archivo ) {
      this.imagenSubir = null;
      return;
    }
 if ( archivo.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

  this.imagenSubir = archivo;
  let reader = new FileReader();
  reader.readAsDataURL( archivo );
  this.extension = archivo.type;
  this.name = archivo.name;
  this.size =archivo.size/1000;

  reader.onloadend = () => this.imagenTemp = reader.result;
  this.sinImagen = true;
  
}
	reset(){
    window.location.reload();
  }

}


