import { Injectable } from '@angular/core';
import { URL_SERVICE } from 'src/app/config/config';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {

  constructor(private http: HttpClient) { }

  subirArchivo(arhivo: File){

  return new Promise( (resolve, reject ) => {
    let formData = new FormData();
    let xhr = new XMLHttpRequest();

    formData.append('src', arhivo);

    xhr.onreadystatechange = function() {

      if ( xhr.readyState === 4 ) {

        if ( xhr.status === 200 ) {
          console.log( 'Imagen subida' );
          resolve( JSON.parse( xhr.response ) );
        } else {
          console.log( 'Fallo la subida' );
          reject( xhr.response );
        }

      }
    };

    let url = URL_SERVICE + '/gallery';

    xhr.open('PUT', url, true );
    xhr.send( formData );

  });

 /*  let url = URL_SERVICE + '/gallery';

		const formData = new FormData(); 
		formData.append('imagenPropia', arhivo, arhivo.name); 
		return this.http.post(url, formData); */

  }

	}

