import { Component, OnInit, Input, Inject, ElementRef } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-agencias',
  templateUrl: './agencias.component.html',
  styleUrls: ['./agencias.component.css']
})
export class AgenciasComponent implements OnInit {
  public agencias: any[];
  public filteredCustomers: any[];
	public searchWord: string;

  constructor(public _sharedService: SharedService, private router: Router, @Inject(DOCUMENT) private document, private elementRef: ElementRef,  private sanitizer: DomSanitizer) { }
  @Input() searchtext;

  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}
  getAgencias(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceAgencias().subscribe(
    resp => {
      if(resp){
        this.agencias = resp;
        swal.close();
							const s = this.document.createElement('script');
							s.type = 'text/javascript';
							s.src = 'assets/js/footable-init.js';
							const __this = this; //to store the current instance to call 
												//afterScriptAdded function on onload event of 
												//script.
							s.onload = function () { __this.afterScriptAdded(); };
							this.elementRef.nativeElement.appendChild(s);
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }

  ngOnInit() {
    this.getAgencias();
  }

  addAgencia(){
    this.router.navigate(['/Newagencia']);
  }
  editAgencia(id:number){

   this.router.navigate(['/agencia/'+id]);

  }

}
