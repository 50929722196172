import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import swal from 'sweetalert2';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  imagenes: any;
  carrusels: any[];

  constructor(public _sharedService: SharedService, public router: Router,  private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getImagenes()
    this.getImagenesAgencia();
  }

  getImagenes(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceImagenes().subscribe(
  
    resp => {
      if(resp){
        this.imagenes = resp;
        swal.close();
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          case 500: { 
            swal("no hay imagenes", "no hay imagenes", "warning");break; 
          }
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }

  addImageCarrusel(imagen :Object){

/* if(this.carrusels.length >= 4){
  swal("no puedes añadir mas imagenes", "Para añadir mas imagenes primero tienes que borrar", "warning"); 

}
else{ */
  this._sharedService.serviceSetImagenesCarrusel(imagen).subscribe(
    resp => {
      if(resp){
        this.reset();
        swal("Imagen Subida", "correcto", "success"); 
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          case 500: { 
            swal("no se ha podido subir la imagen", "error", "warning");break; 
          }
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  /*   } */
}
  getImagenesAgencia(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.servicesAgenciaCarrusel().subscribe(
    resp => {
      if(resp){
        this.carrusels = resp;
        swal.close();
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          case 500: { 
            swal("no hay imagenes en el carrusel", "no hay imagenes", "warning");break; 
          }
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }


  deleteImagenesCarrusel(id : Number){
    let image = {};
    image['id'] = id;
    swal({
      title: 'Borrar Imagen',
      text: "¿Esta seguro que quieres borrar el Borrar Imagen?",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
    this._sharedService.serviceDeleteCarrusel(image).subscribe(
    resp => {
      if(resp){
        this.reset();
        swal('la imagen se ha borrado', '', 'success');
        
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      }
    )
    swal(
      'Imagen borrado',
      'Imagen se ha borrado',
      'success'
      );
    }
  })  
}
  reset(){
    window.location.reload();
  }

}
