import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { SharedService } from 'src/app/services/service.index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-ofertas',
  templateUrl: './new-ofertas.component.html',
  styleUrls: ['./new-ofertas.component.css']
})
export class NewOfertasComponent implements OnInit {
newOferta :any;
  addFichero: any;
  extension: any;
  name: any;
  size: any;
  fichero: File;
  constructor(public _sharedService: SharedService,private router: Router) { }

  ngOnInit() {
    this.initOferta();
  }

  initOferta(){

    this.newOferta = {
      numero: '', 
      nombre:'',
      origen:'',
      destino:'', 
      fechaIni:'', 
      fechaFin:'',
    };
  }

  seleccionArchivo( archivo: File) {
  if ( !archivo ) {
      return;
    } 
 if ( archivo.type.indexOf('application/pdf') < 0 ) {
      swal('Sólo Ficheros', 'El archivo seleccionado no es un archivo PDF', 'error');
      return;
    }

  this.fichero = archivo;
  let reader = new FileReader();
  reader.readAsDataURL( archivo );
  this.extension = archivo.type;
  this.name = archivo.name;
  this.size =archivo.size/1000;

  reader.onloadend = () => this.addFichero = reader.result;
  
}

  subirOferta(){
    this.newOferta ['file'] = this.addFichero;
    this.newOferta ['type'] = this.extension;
    this.newOferta['nameFich'] = this.name;
    this.newOferta['size'] = this.size;
    swal({
      text: 'Guardando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
          this._sharedService.serviceAddOferta(this.newOferta).subscribe(
            resp => {
              swal.close();
              if(resp){
                swal('archivo', 'archivo subido', 'success');
                this.router.navigate(['/ofertas']);
              } else {
                console.log("Response error: " + resp);
              }
            },
            err => {     
              swal.close();
              //console.log("Server error: " + err);
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
              }
            }
          );
        }
      });

  }

}
