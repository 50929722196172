import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, SharedService } from 'src/app/services/service.index';
declare function init_plugins();
@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  //styleUrls: ['./resetpass.component.css']
  styles: []
})
export class ResetpassComponent implements OnInit {

  forma: FormGroup;
  constructor(public _sharedService: SharedService, private router: Router) {

    this.forma = new FormGroup({

      'password': new FormControl('', [Validators.required, Validators.minLength(4)])
    });
   }

  ngOnInit() {
  }

  goback(){
    this.router.navigate(['/cuenta']);
  }

reset(){

let password = this.forma.controls.password.value;
let mail =localStorage.getItem('user'); 

if(!this.forma.valid){
  console.log('fail');
  swal("Resetar Contraseña", "debe llevar al menos 4 digitos", "warning");
}
else{
 this._sharedService.serviceResetPass(mail,password).subscribe(res =>{ 
  console.log('result',res);
    if(res) {
      this.router.navigate(['/login']);
      swal("Cambio", "Cambio de contraseña correcto", "success");
      
    }else{
      swal("Resetear contraseña", "No se puede resetear la contraseña", "error");
            }}, error => {
            //adios spinner molon
            switch(error.status) { 
              case 401: { 
                  swal("Resetear contraseña401", "No se puede resetear la contraseña", "error");
              break; 
              }
              case 403: { 
                swal("Resetear contraseña403", "No se puede resetear la contraseña", "error");
              break; 
            }                                                     
             default: { 
              swal("Resetear contraseñadefa", "No se puede resetear la contraseña", "error");
              break; 
              }                             
            }
      });
    };

    }
  }
  
