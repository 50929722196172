import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Srv } from 'src/app/models/srv.model';

import {isNumeric} from "rxjs/util/isNumeric"

import { URL_SERVICE } from 'src/app/config/config';
import { SubirArchivoService } from '../subirArchivo/subir-archivo.service';


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  reload: EventEmitter<any> = new EventEmitter();
  GoReload(target) {
    this.reload.emit(target);
  }  
  GetReload() {
    return this.reload;
  }

  searchword: EventEmitter<string> = new EventEmitter();
  SetSearchWord(word) {
    this.searchword.emit(word);
  }
  GetSearchWord() {
    return this.searchword;
  }

  

  constructor(
    public http: HttpClient,public _subirArchivoService: SubirArchivoService
  ) { 
    console.log('SharedService Ready!');
  }


  prepareRes(data:any,where?:any,group?:string){
    let ret = [];
    let aux = data;        

    if(where === undefined || where == "" ){
      aux = data;
    }else if(typeof(where) == 'object'){
      aux = data;
      for (let field in where) {
        if (typeof(where[field]) == 'string'){
          aux = aux.filter(i => (i[field].toUpperCase().search(where[field].toUpperCase())>=0));
        }else if (typeof(where[field]) == 'number'){
          aux = aux.filter(i => (i[field] == where[field]));
        }
        
      }

    }else if(typeof(where) == 'number' || isNumeric(where)){
        
      aux = data.filter(item => {
        for (let fld in item) {        
            if (typeof(item[fld]) == 'number'){
              if (item[fld] == where){
                return true;
              }
            }
        }
       });

    }else if(typeof(where) == 'string'){
      
      aux = data.filter(item => {
        for (let fld in item) {        
            if (typeof(item[fld]) == 'string'){
              if (item[fld].toUpperCase().search(where.toUpperCase())>=0){
                return true;
              }
            }
        }
       });

    }else{
      aux = data;
    }

    if (group === undefined || group == ""){
      ret = aux;
    }else{
      let gro = new Set(aux.map(item => item[group]));
      gro.forEach(g => 
        ret.push({
          Group: g, 
          Items:  aux.filter(i => i[group] === g)
        }
      ));         
    }

    return ret;
  }

  serviceExps (filter: string) {
    let url = URL_SERVICE + '/getExps/' + filter;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceExp (exp:number) {
    let url = URL_SERVICE + '/getExp/' + exp;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceAgencias () {
    let url = URL_SERVICE + '/getAgencias';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceUsuarios () {
    let url = URL_SERVICE + '/getUsuarios';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  serviceUsuariosOfertas () {
    let url = URL_SERVICE + '/getUsuariosOfertas';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }



  serviceAgencia (id:number) {
    let url = URL_SERVICE + '/getAgencia/' + id;
    return this.http.get(url)
                .pipe(map((res: any) => {             
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceLogo (id:number) {
    let url = URL_SERVICE + '/getLogo/' + id;
    return this.http.get(url)
                .pipe(map((res: any) => {             
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }


  serviceUserAPP (id:number) {
    let url = URL_SERVICE +   '/getUsuarioAPP/' + id;
    return this.http.get(url)
                .pipe(map((res: any) => {             
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceOfertasEnviadas (id :object) {
    let url = URL_SERVICE + '/setOfertasEnviadas';
    return this.http.post(url, id)
                .pipe(map((res: any) => {             
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }



  serviceCuenta () {
    let url = URL_SERVICE + '/getCuenta';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceResetPass(mail:string,pass: string ) {
    let url = URL_SERVICE + '/chgPass';
    return this.http.put(url,{'mail': mail,'pass': pass},{responseType: "text",  observe: "response"}).pipe(
      map((resp: any) => {
      return resp;
      
    }),catchError((err: any) => {
      return throwError(err);
    })
      );
    
    }

    serviceAddAgencia (agencia: object) {
      let url = URL_SERVICE + '/addAgencia';
  
      return this.http.post(url, agencia).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }

    serviceAddUserAPP(userAPP: object) {
      let url = URL_SERVICE + '/addUsuarioAPP';
  
      return this.http.post(url, userAPP).pipe(
        map((res: any) => {
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }

    serviceSetAgencia (agencia: object) {
      let url = URL_SERVICE + '/setAgencia';
  
      return this.http.post(url, agencia).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }
    serviceSetUserAPP (userAPP: object) {
      let url = URL_SERVICE + '/setClienteAPP';
  
      return this.http.post(url, userAPP).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }
    serviceDeleteAgencia_UserAPP (Agencia_UserAPP: object) {
      let url = URL_SERVICE + '/setDelete';
  
      return this.http.post(url, Agencia_UserAPP).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }

    serviceDeleteOferta (id: object) {
      let url = URL_SERVICE + '/setDeleteOfertas';
      return this.http.post(url,id).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }

    serviceDeleteViajes (viaje: object) {
      let url = URL_SERVICE + '/setDeleteViajes';
  
      return this.http.post(url, viaje).pipe(
        map((res: any) => {
          console.log(res);
          return res;
        }),
        catchError((err: any) => {
          return throwError(err);
        })
          );
    }

  serviceOpes () {
    let url = URL_SERVICE + '/getOpes';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  serviceGallery (file : Object) {
    let url = URL_SERVICE + '/gallery';
    return this.http.post(url, file).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceGalleryPrincipal (file : Object) {
    let url = URL_SERVICE + '/galleryPrincipal';
    return this.http.post(url, file).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceArchivoAdd (file : Object) {
    let url = URL_SERVICE + '/addArchivos';
    return this.http.post(url, file).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  servicePlantillaAdd (file : Object) {
    let url = URL_SERVICE + '/addPlantilla';
    return this.http.post(url, file).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }
  serviceSeguroAdd (file : Object) {
    let url = URL_SERVICE + '/addSeguros';
    return this.http.post(url, file).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceAddOferta (object : Object) {
    let url = URL_SERVICE + '/addOfertas';
    return this.http.post(url, object).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceAddOfertaUser_APP (id: Object, usu: Object) {
    let url = URL_SERVICE + '/addOfertasUser_APP';
    return this.http.post(url,{'id': id, 'usu': usu}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }



  serviceImagenes() {
    let url = URL_SERVICE + '/getImagenes';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceImagenesPrincipal() {
    let url = URL_SERVICE + '/getImagenesPrincipal';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }


  servicesAgenciaCarrusel() {
    let url = URL_SERVICE + '/getImagenesCarrusel/';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }



  serviceDeleteCarrusel (image : Object) {
    let url = URL_SERVICE + '/deleteCarrusel';
    console.log(image);
    return this.http.post(url, image).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceUpdateLogo (image : Object) {
    let url = URL_SERVICE + '/setAgenciaLogo';
    console.log(image);
    return this.http.post(url, image).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }
  
  serviceSetImagenesCarrusel (image: object) {
    let url = URL_SERVICE + '/setCarrusel';

    return this.http.post(url, image).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceSetPlantillaViaje (plantilla: object, id : number) {
    let url = URL_SERVICE + '/setViajePlantilla/'+ id;

    return this.http.post(url, plantilla).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }



  serviceGetViajeDetalles(id: Object) {
    let url = URL_SERVICE + '/getViajeD/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceGetViajePlantillas(id: Object) {
    let url = URL_SERVICE + '/getViajePlantillas/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceGetOfertas() {
    let url = URL_SERVICE + '/getOfertas/';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  serviceArchivos(id: Object) {
    let url = URL_SERVICE + '/getArchivos/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }


  serviceViajes(id: Object) {
    let url = URL_SERVICE + '/getViaje/'+ id;
    return this.http.get(url,id)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceArchivosAgencia(id: Object) {
    let url = URL_SERVICE + '/getArchivosAgencia/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  serviceSegurosAgencia(id: Object) {
    let url = URL_SERVICE + '/getSegurosAgencia/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceViajesAgencia(id: Object) {
    let url = URL_SERVICE + '/getViajesAgencia/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceDeleteArchivo (image : Object) {
    let url = URL_SERVICE + '/deleteArchivos';
    console.log(image);
    return this.http.post(url, image).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceDeletePlantillaViaje (plantilla : Object, id: number) {
    let url = URL_SERVICE + '/deleteViajePlantillas/'+ id;
    console.log(plantilla);
    return this.http.post(url, plantilla).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }


  serviceDeletePlantilla (plantilla : Object) {
    let url = URL_SERVICE + '/deletePlantillas';
    return this.http.post(url, plantilla).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

serviceGetViaje(id: Object) {
    let url = URL_SERVICE + '/getViajeIDArchivos/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceGetViajeA(id: Object) {
    let url = URL_SERVICE + '/getViajeIDSeguros/'+ id;
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

  serviceGetViajePlantiAdmin() {
    let url = URL_SERVICE + '/getViajeIDPlantilla';
    return this.http.get(url)
                .pipe(map((res: any) => {                  
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  
  serviceAddViaje (viaje : Object) {
    let url = URL_SERVICE + '/addViaje';
    return this.http.post(url, viaje).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }
  serviceUpdateViajeDetalle (viaje : Object) {
    let url = URL_SERVICE + '/updateViajeDe';
    return this.http.post(url, viaje).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
        );
  }

  serviceAddSrv (srv: Srv) {
    let url = URL_SERVICE + '/addSrv/' + srv.NNUMEXP;
    return this.http.post(url, srv)
                .pipe(map((res: any) => {                                    
                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }

}
