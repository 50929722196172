import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";

// AUTH Interceptor
import { TokenInterceptor } from "./auth/token.interceptor";
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
    AuthService,
    AuthGuard,
    SharedService,
    SidebarService    
} from './service.index';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        HttpClientModule,        
        AuthService,
        AuthGuard,
        SharedService,
        SidebarService        
    ],
    declarations: []
})
export class ServiceModule { }