import { Component, OnInit, Input, ElementRef, Inject } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/service.index';
import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-clientes-app',
  templateUrl: './clientes-app.component.html',
  styleUrls: ['./clientes-app.component.css']
})
export class ClientesAPPComponent implements OnInit {
  public usuarios: any[];
  public filteredCustomers: any[];
	public searchWord: string;

  constructor(public _sharedService: SharedService, private router: Router, @Inject(DOCUMENT) private document, private elementRef: ElementRef) { }
  @Input() searchtext;

  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}
  getUsuarios(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceUsuarios().subscribe(

    resp => {
      if(resp){
        this.usuarios = resp;
        swal.close();
        	
							const s = this.document.createElement('script');
							s.type = 'text/javascript';
							s.src = 'assets/js/footable-init.js';
							const __this = this; //to store the current instance to call 
												//afterScriptAdded function on onload event of 
												//script.
							s.onload = function () { __this.afterScriptAdded(); };
							this.elementRef.nativeElement.appendChild(s);
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    );
  }
});
  }

  ngOnInit() {
    this.getUsuarios();
  }

  addCliente(){
    this.router.navigate(['/NewClienteAPP']);
  }
  editClienteAPP(id:number){

   this.router.navigate(['/clienteAPP/'+id]);

  }

sendNotificacion(){


  }

}
