import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { SharedService, AuthService } from 'src/app/services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import swal from 'sweetalert2';

@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.css']
})
export class PlantillasComponent implements OnInit {
  iduser: any;
  addFichero: any;
  extension: any;
  name: any;
  size: any;
  fichero: File;
  plantillas: any;

  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute,public router: Router,public _authService: AuthService,@Inject(DOCUMENT) private document, private elementRef: ElementRef) { 
    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
  }
  
  @Input() searchtext;
  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
  }
  

  ngOnInit() {
    this.getPlantilla();

  }

  
  getPlantilla(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceGetViajePlantiAdmin().subscribe(
       resp => {
         if(resp){
           this.plantillas = resp;
           swal.close();
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay documentos", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
      }

  seleccionArchivo( archivo: File) {
    if ( !archivo ) {
      return;
    }
 if ( archivo.type.indexOf('application/pdf') < 0 ) {
      swal('Sólo Ficheros', 'El archivo seleccionado no es un archivo PDF', 'error');
      return;
    }

  this.fichero = archivo;
  let reader = new FileReader();
  reader.readAsDataURL( archivo );
  this.extension = archivo.type;
  this.name = archivo.name;
  this.size =archivo.size/1000;

  reader.onloadend = () => this.addFichero = reader.result;
  
}

  subirPlantilla(){
    let  body = {};
    body['file'] = this.addFichero;
    body['type'] = this.extension;
    body['name'] = this.name;
    body['size'] = this.size;
    body['idViaje'] = this.iduser;
    swal({
      text: 'Guardando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
          this._sharedService.servicePlantillaAdd(body).subscribe(
            resp => {
              swal.close();
              if(resp){
               this.reset();
                
                swal('archivo', 'archivo subido', 'success');
              } else {
                console.log("Response error: " + resp);
              }
            },
            err => {     
              swal.close();
              //console.log("Server error: " + err);
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
              }
            }
          );
        }
      });

  }

  deleteArchivo(id : Number){
    let archivo = {};
    archivo['id'] = id;
    swal({
      title: 'Borrar Archivo',
      text: "¿Esta seguro que quieres borrar el Borrar Archivo:",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
    this._sharedService.serviceDeletePlantilla(archivo).subscribe(
    resp => {
      if(resp){
        this.reset();
        swal('el archivo se ha borrado', '', 'success');
        
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      }
    )
    swal(
      'Archivo borrado',
      'Archivo se ha borrado',
      'success'
      );
    }
  })  
}
reset(){
  window.location.reload();
}

saveAsArchivo(data: any){
  console.log(data);
  saveAs(new Blob([new Uint8Array(data.BFICHERO.data)]),data.CNOMFICH); //FileSaver.js
  }

}
