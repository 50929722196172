import { Component, OnInit, Input, Inject, ElementRef } from '@angular/core';
import { SharedService} from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT, DomSanitizer } from '@angular/platform-browser';
import { URL_SERVICE } from 'src/app/config/config';
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-agencia',
  templateUrl: './agencia.component.html',
  styleUrls: ['./agencia.component.css']
})
export class AgenciaComponent implements OnInit {
  public agencia: Object;
  public iduser:number;
  archivos: any[];
  saveAs:any;
  imagenSubir: any;
  extension: string;
  name: string;
  size: number;
  imagenTemp: string | ArrayBuffer;
  code: any;
  seguros: any;
  viajes: any;
  constructor(public _sharedService: SharedService,public activatedRoute: ActivatedRoute,public router: Router,@Inject(DOCUMENT) private document, private elementRef: ElementRef, private sanitizer: DomSanitizer) { 

    activatedRoute.params.subscribe( params => {
      this.iduser = params['id'];
    });
  }
  @Input() searchtext;
  afterScriptAdded() {
		const params= {
		  width: '350px',
		  height: '420px',
		};
		if (typeof (window['functionFromExternalScript']) === 'function') {
		  window['functionFromExternalScript'](params);
		}
	}

  ngOnInit() {
    this.getAgencia();
    this.getDocuments();
    this.getViajes();
    this.getSeguros();
  }
  
  getDocuments(){
    swal({
      text: 'Cargando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
    this._sharedService.serviceArchivosAgencia(this.iduser).subscribe(
       resp => {
         if(resp){
           this.archivos = resp;
           swal.close();
                 const s = this.document.createElement('script');
                 s.type = 'text/javascript';
                 s.src = 'assets/js/footable-init.js';
                 const __this = this; //to store the current instance to call 
                           //afterScriptAdded function on onload event of 
                           //script.
                 s.onload = function () { __this.afterScriptAdded(); };
                 this.elementRef.nativeElement.appendChild(s);
         } else {
           console.log("Response error: " + resp);
         }
         },
         err => {     
           swal.close();
           //console.log("Server error: " + err);
           switch(err.status) { 
             case 500: { 
               swal("no hay documentos", "", "warning");
             break;               
             }
             default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
           }
         } 
       );
      }
    });
}

     getSeguros(){
      swal({
        text: 'Cargando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();
      this._sharedService.serviceSegurosAgencia(this.iduser).subscribe(  
         resp => {
           if(resp){
             this.seguros = resp;
             swal.close();
                   const s = this.document.createElement('script');
                   s.type = 'text/javascript';
                   s.src = 'assets/js/footable-init.js';
                   const __this = this; //to store the current instance to call 
                             //afterScriptAdded function on onload event of 
                             //script.
                   s.onload = function () { __this.afterScriptAdded(); };
                   this.elementRef.nativeElement.appendChild(s);
           } else {
             console.log("Response error: " + resp);
           }
           },
           err => {     
             swal.close();
             //console.log("Server error: " + err);
             switch(err.status) { 
               case 500: { 
                 swal("no hay Seguros", "", "warning");
               break;               
               }
               default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
             }
           } 
         );
        }
      });
        }


   getViajes(){
          swal({
            text: 'Cargando...',
            allowOutsideClick: false,
            onBeforeOpen: () => {
              swal.showLoading();
          this._sharedService.serviceViajesAgencia(this.iduser).subscribe(  
             resp => {
               if(resp){
                 this.viajes = resp;
                 swal.close();
                       const s = this.document.createElement('script');
                       s.type = 'text/javascript';
                       s.src = 'assets/js/footable-init.js';
                       const __this = this; //to store the current instance to call 
                                 //afterScriptAdded function on onload event of 
                                 //script.
                       s.onload = function () { __this.afterScriptAdded(); };
                       this.elementRef.nativeElement.appendChild(s);
               } else {
                 console.log("Response error: " + resp);
               }
               },
               err => {     
                 swal.close();
                 //console.log("Server error: " + err);
                 switch(err.status) { 
                   case 500: { 
                     swal("no hay Seguros", "", "warning");
                   break;               
                   }
                   default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
                 }
               } 
             );
            }
          });
            }

  getAgencia(){
    swal({
			text: 'Cargando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._sharedService.serviceAgencia(this.iduser).subscribe(

    resp => {
      if(resp){
        this.agencia = resp[0];
        this.code = resp[0].CCODFICH;
        swal.close();
        	
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });
  }
  setAgencia(){
/*     this.agencia['file'] = this.imagenTemp;
    this.agencia['type'] = this.extension;
    this.agencia['namef'] = this.name;
    this.agencia['size'] = this.size;
    this.agencia['code'] = this.code;
		console.log(this.agencia); */
    swal({
			text: 'Guardando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
    this._sharedService.serviceSetAgencia(this.agencia).subscribe(
    resp => {
      if(resp){
        swal.close();
        this.reset();
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      } 
    )
  }
  });
  }
  deleteAgencia(){
    swal({
      title: 'Borrar Agencia',
      text: "¿Esta seguro que quieres borrar la agencia",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        this._sharedService.serviceDeleteAgencia_UserAPP(this.agencia).subscribe(
          resp => {
            if(resp){
              swal.close();
              this.router.navigate(['/agencias']);
            } else {
              console.log("Response error: " + resp);
            }
            },
            err => {     
              swal.close();
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
              }
            } 
          )
        swal(
          'Agencia borrada',
          'La agencia se ha borrado',
          'success'
        );
      }
    })  
  }
  reset(){
    window.location.reload();
  }
  newImagen(){

    this.router.navigate(['/imagenes']);
   }

   updateLogo(id : number){

    this.router.navigate(['/updateLogo/'+id]);

   }

saveAsArchivo(data: any){
  saveAs(new Blob([new Uint8Array(data.BFICHERO.data)]),data.CNOMFICH); //FileSaver.js
  }
}
