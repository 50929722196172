import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';

import { AuthGuard } from '../services/service.index';
import { DashAgenciaComponent } from './dash-agencia/dash-agencia.component';
import { AgenciasComponent } from './dashboard/agencias/agencias.component';
import { CuentaComponent } from './dashboard/cuenta/cuenta.component';
import { PlantillasComponent } from './dashboard/plantillas/plantillas.component';
import { GaleriaComponent } from './dashboard/galeria/galeria.component';
import { ClientesAPPComponent } from './dash-agencia/clientes-app/clientes-app.component';
import { DocumentosComponent } from './dash-agencia/documentos/documentos.component';
import { ResetpassComponent } from './resetpass/resetpass.component';
import { AgenciaComponent } from './dashboard/agencia/agencia.component';
import { NewAgenciaComponent } from './dashboard/new-agencia/new-agencia.component';
import { ClienteAppComponent } from './dash-agencia/cliente-app/cliente-app.component';
import { NewClienteAppComponent } from './dash-agencia/new-cliente-app/new-cliente-app.component';
import { ImagenesComponent } from './dashboard/galeria/imagenes/imagenes.component';
import { ViajesComponent } from './dash-agencia/viajes/viajes.component';
import { UpdateLogoComponent } from './dashboard/agencia/update-logo/update-logo.component';
import { NewViajeComponent } from './dash-agencia/viajes/new-viaje/new-viaje.component';
import { OfertasComponent } from './dash-agencia/ofertas/ofertas.component';
import { NewOfertasComponent } from './dash-agencia/ofertas/new-ofertas/new-ofertas.component';
import { SendOfertasComponent } from './dash-agencia/ofertas/send-ofertas/send-ofertas.component';
import { PantallPrincipalComponent } from './dashboard/pantall-principal/pantall-principal.component';
import { ImagenPrincipalComponent } from './dashboard/pantall-principal/imagen-principal/imagen-principal.component';


const pagesRoutes: Routes = [
    { path: '', component: PagesComponent,
    canActivate: [ AuthGuard ],
        children: [
             /* Admin*/
                {path: 'dashboard', component: DashboardComponent},
                {path: 'agencias', component: AgenciasComponent},
                {path: 'plantillas', component: PlantillasComponent},
                {path: 'galeria', component: GaleriaComponent},
                {path: 'agencia/:id', component: AgenciaComponent},
                {path: 'Newagencia', component: NewAgenciaComponent},
                {path: 'imagenes', component:ImagenesComponent},
                {path: 'updateLogo/:id', component:UpdateLogoComponent},
                { path: 'principal', component: PantallPrincipalComponent},
                { path: 'imagenPrincipal', component: ImagenPrincipalComponent},

                
                /* Agencia */
                { path: 'dashboard-agencia', component: DashAgenciaComponent},
                { path: 'clientesAPP', component: ClientesAPPComponent},
                { path: 'clienteAPP/:id', component: ClienteAppComponent},
                { path: 'documentos', component: DocumentosComponent},
                { path: 'NewClienteAPP', component: NewClienteAppComponent},
                { path: 'viaje/:id', component: ViajesComponent},
                { path: 'newViaje/:id', component: NewViajeComponent},
                { path: 'ofertas', component: OfertasComponent},
                { path: 'newOferta', component: NewOfertasComponent},
                { path: 'sendOferta/:id', component: SendOfertasComponent},
               


                /* reset */
                { path: 'reset', component: ResetpassComponent},
                /* comun */
                {path: 'cuenta', component: CuentaComponent},
                { path: '', redirectTo: '/cuenta', pathMatch: 'full'},


    
            ]},

          /*   { path: 'dashboard-agencia', component: DashAgenciaComponent}, */
            { path: '', redirectTo: '/cuenta', pathMatch: 'full'},
           
        
    
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);