import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';

//Router
import { APP_ROUTES } from './app.routes';

//Modules
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';

//Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './login/recover.component';
import { LOPDComponent } from './lopd/lopd.component';
//Services
import { ServiceModule } from './services/service.module';

//añadido
import { ScrollingModule } from '@angular/cdk/scrolling';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoverComponent,
    LOPDComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    PagesModule,
    SharedModule,
    ServiceModule,
    APP_ROUTES,
    ScrollingModule
  ],
  exports: [
    BrowserModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
