import { Component, Input, Output, ViewChild, OnInit, EventEmitter, HostListener, HostBinding, ViewEncapsulation } from '@angular/core';
import { RouterOutlet, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DashboardComponent } from 'src/app/pages/dashboard/dashboard.component';
import { filter } from 'rxjs/operators';
import { SharedService } from 'src/app/services/service.index';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public _sharedService: SharedService
    ) { 
    }

  ngOnInit() {

  }

  goBack(){
    this.router.navigate(['../'])
  }

  goHome(){
    window.location.reload();
  }

  goReload(){    
    this._sharedService.GoReload(DashboardComponent);
  }

  searchWord(word: string){    
    this._sharedService.SetSearchWord(word);
  }


}
