import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/services/service.index';
import swal from 'sweetalert2';

@Component({
  selector: 'app-new-cliente-app',
  templateUrl: './new-cliente-app.component.html',
  styleUrls: ['./new-cliente-app.component.css']
})
export class NewClienteAppComponent implements OnInit {

	public userAPP: Object;
  constructor(public router: Router, public _sharedService: SharedService,) { }

  ngOnInit() {
    this.initUserAPP();
  }

  initUserAPP(){

    this.userAPP = {
      mail: '', 
      name:'',
      empresa:'', 
      dni:'', 
      direccion:'', 
      poblacion:'', 
      cp:'', 
      provincia:'', 
			telefono:'',
			check:''
    };
  }

  addUserAPP(){
		console.log(this.userAPP);
    swal({			
			text: 'Guardando...',
			allowOutsideClick: false,
			onBeforeOpen: () => {
				swal.showLoading();
					this._sharedService.serviceAddUserAPP(this.userAPP).subscribe(
						resp => {
							swal.close();
							if(resp){
								this.router.navigate(['/clientesAPP']);
							} else {
								console.log("Response error: " + resp);
							}
						},
						err => {     
							swal.close();
							//console.log("Server error: " + err);
							switch(err.status) { 
								case 401: swal('Mail Existente', 'El mail ya está registrado en la plataforma.', 'error'); break;
								default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
							}
						}
					);
				}
			});

  }
}
