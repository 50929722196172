import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/service.index';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

@Component({
  selector: 'app-imagen-principal',
  templateUrl: './imagen-principal.component.html',
  styleUrls: ['./imagen-principal.component.css']
})
export class ImagenPrincipalComponent implements OnInit {
  imagenSubir: any;
  extension: string;
  name: string;
  size: number;
  imagenTemp: string | ArrayBuffer;

  constructor(public _sharedService: SharedService,  private router: Router ) { }

  ngOnInit() {
  }
  seleccionImage( archivo: File) {

    if ( !archivo ) {
      this.imagenSubir = null;
      return;
    }
 if ( archivo.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imagen', 'error');
      this.imagenSubir = null;
      return;
    }

  this.imagenSubir = archivo;
  let reader = new FileReader();
  reader.readAsDataURL( archivo );
  this.extension = archivo.type;
  this.name = archivo.name;
  this.size =archivo.size/1000;

  reader.onloadend = () => this.imagenTemp = reader.result;
  
}

  subirImagen(){
    let  body = {};
    body['file'] = this.imagenTemp;
    body['type'] = this.extension;
    body['name'] = this.name;
    body['size'] = this.size;
    console.log(body);
    swal({
      text: 'Guardando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        swal.showLoading();
          this._sharedService.serviceGalleryPrincipal(body).subscribe(
            resp => {
              swal.close();
              if(resp){
                swal.close();
                this.router.navigate(['/principal']);
                swal('Imagen', 'imagen subida', 'success');
              } else {
                console.log("Response error: " + resp);
              }
            },
            err => {     
              swal.close();
              //console.log("Server error: " + err);
              switch(err.status) { 
                default: swal('Error de Servidor', 'No se ha podido actualizar debido a un error interno del servidor.', 'error'); break;
              }
            }
          );
        }
      });

  }
}
