import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from "@angular/common";

import { NopagefoundComponent } from "./404/nopagefound.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { PipesModule } from '../pages/pipes/pipes.module';

@NgModule({
    declarations: [        
        NopagefoundComponent,
        BreadcrumbComponent,
        HeaderComponent,
        SidebarComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        PipesModule
    ],
    exports: [
        NopagefoundComponent,
        BreadcrumbComponent,
        HeaderComponent,
        SidebarComponent,
        BrowserModule
    ]
})
export class SharedModule { }