import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { User } from 'src/app/models/user.model';

import { URL_SERVICE } from 'src/app/config/config';


@Injectable({providedIn: 'root'})
//@Injectable()
export class AuthService {

  user: string;
  token: string;
  userRole: string;
  id: number;
  

  constructor(
    public router: Router,
    public http: HttpClient
    ) { 
    this.serviceLoadStorage();
    console.log('AuthService Ready!');
  }

  serviceIsLogged (){
    
    try {
      let payload = JSON.parse( atob( this.token.split('.')[1] ));
      return this.checkTokenExp(payload.exp) && (payload.user == this.user);
    }
    catch(err) {
      return false;
    }    
  
  }

  private checkTokenExp ( exp: number ): boolean  {
      let tokenExp = new Date( exp * 1000 );
      let now = new Date();

      now.setTime( now.getTime() + ( 1 * 60 * 60 * 1000 ) );

      if ( tokenExp.getTime() > now.getTime() ) {
        return true;
      } else {
        return false;
      }

  }

  serviceLoadStorage() {
   if (localStorage.getItem('token') && localStorage.getItem('user')){
      this.token = localStorage.getItem('token') || '';
      this.user = localStorage.getItem('user') || '';
this.userRole = localStorage.getItem('userRole') || '';
    }
  }

  serviceLogout(){
      this.token = ''; 
      localStorage.removeItem('user');
      localStorage.removeItem('token');
localStorage.removeItem('userRole');
      this.router.navigate(['/login']);
  }
  
  serviceLogin (user: User, remember: boolean = false) {
    let url = URL_SERVICE + '/login';

    if (remember){
      localStorage.setItem('mail',user.mail);
    }else{
      localStorage.removeItem('mail');
    }

    return this.http.post(url, user)
                .pipe(map((res: any) => { 
                  localStorage.setItem('user',user.mail);
                  localStorage.setItem('token',res.token);
                  localStorage.setItem('userRole',res.userRole);
                  
                  this.userRole = res.userRole
                  this.user = user.mail;
                  this.token = res.token;
                  this.id = res.id;

                  return res;
                }),catchError((err: any) => {
                    return throwError(err);
                })
              );
  }
  serviceRecPass (user: string) {
     let url = URL_SERVICE + '/recPass/' + user;
    return this.http.post(url, user)
                .pipe(map((res: any) => {                                    
                  return res;
                }),catchError((err: any) => {
                    this.resolveError(err);
                    return throwError(err);
                })
              );
  }


  serviceLOPD (mail : Object) {
    let url = URL_SERVICE + '/LOPD';
   return this.http.post(url, mail)
               .pipe(map((res: any) => {                                    
                 return res;
               }),catchError((err: any) => {
                   this.resolveError(err);
                   return throwError(err);
               })
             );
 }


  resolveError(err:any) {
    if (err.status>=400 && err.status<=499){
      this.router.navigate(['/login'])
    }                  
  }
  

}
