import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICE } from 'src/app/config/config';
@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {
 
  transform(img: String, args?: any): any {
    let url;
  if(!img){
  return url + 'fuera';
}

     url = URL_SERVICE + '/gallery';
    return url;
  }

}
