import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms/src/directives';
import { Router } from '@angular/router';

import { AuthService } from '../services/service.index';

import { User } from '../models/user.model';

import swal from 'sweetalert2';

declare function initCustomJS();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  mail: string;
  remember: boolean = false;

  constructor( 
    public router: Router,
    public _authService: AuthService
  ) { }

  ngOnInit() { 
    initCustomJS();
    this.mail = localStorage.getItem('mail') || '';
    if (this.mail.length>0){
      this.remember=true;
    }
  }



  login (form: NgForm){
    if (!form.valid){
      swal("Iniciar sesión", "Los campos de inicio de sesión no son correctos", "warning");
    }else{
      let user = new User(form.value.mail,form.value.password);  
      swal({
        text: 'Iniciando sesión...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          swal.showLoading();          
          this._authService.serviceLogin(user,form.value.remember)
          .subscribe(respx => {
            swal.close();
            if(respx){
              if(respx.firmaAgencia === 0){
                this.router.navigate(['/lopd'])
              }
              else{
              if(respx.userRole === 'administradores'){
                this.router.navigate(['/dashboard'])
              }
              if(respx.userRole === 'agencias'){
                this.router.navigate(['/dashboard-agencia'])
              }
            }
            }else{
              swal("Iniciar sesión", "No se pudo iniciar sesión", "error");
            }
          },err => {     
            swal.close();
            switch(err.status) { 
              case 400: { 
                swal("Iniciar sesión", "Error de acceso", "error");
              break;               
              }
              case 401: { 
                  swal("Iniciar sesión", "Usuario o contraseña incorrecta.", "error");
              break; 
              }
              case 406: { 
                swal("Iniciar sesión", "La cuenta está desactivada.", "error");
              break; 
              }
              case 408: { 
                swal("Iniciar sesión", "No se pudo contectar con el servidor.", "error");
              break; 
              }                            
              case 500: { 
                swal("Iniciar sesión", "Error interno en el servidor.", "error");
              break; 
              }                                                      
             default: { 
                swal("Iniciar sesión", "No se pudo iniciar sesión.", "error");
              break; 
              }                             
            }
          });

        }

      });                        
      
    }
    
  }

}
