import { Component, OnInit } from '@angular/core';
import { AuthService} from 'src/app/services/service.index';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(
    public router: Router,
    public _authService: AuthService,
  ) { } 

  ngOnInit() {
  }
  goAgencias(){
    this.router.navigate(['/agencias']);
  }
  goPlantillas(){
    this.router.navigate(['/plantillas']);
  }
  goGaleria(){
    this.router.navigate(['/galeria']);
  }
  goDash(){
    this.router.navigate(['/dashboard']);
  }
  goClientesAPP(){
    this.router.navigate(['/clientesAPP']);
  }
  goSitios(){
    this.router.navigate(['/sitios']);
  }
  goDocumentos(){
    this.router.navigate(['/documentos']);
  }
  goDinfoDas(){
    this.router.navigate(['/dashboard-agencia']);
  }
  goCuenta(){
    this.router.navigate(['/cuenta']);
  }

  goReset(){
    this.router.navigate(['/reset']);
  }
  goNewAgencia(){
    this.router.navigate(['/Newagencia']);
  }
  goNewClienteAPP(){
    this.router.navigate(['/NewClienteAPP']);
  }

  goImagenes(){
    this.router.navigate(['/imagenes']);
  }

  goOfertas(){

    this.router.navigate(['/ofertas']);

  }
  goPrinci(){
    this.router.navigate(['/principal']);
  }


 
  logout() {   

    swal({
      title: 'Cerrar sesión',
      text: "¿Esta seguro de finalzar la sesión?",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
        swal(
          'Cerrar sesión',
          'La sesión ha finalizado.',
          'success'
        );
        this._authService.serviceLogout();
      }
    })    
  }

}
