import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RecoverComponent } from './login/recover.component';
import { NopagefoundComponent } from './shared/404/nopagefound.component';
import { LOPDComponent } from './lopd/lopd.component';

const appRoutes: Routes = [
    { path: 'login', component: LoginComponent},
    { path: 'recover', component: RecoverComponent},
    { path: 'lopd', component: LOPDComponent},
    { path: '**', component: NopagefoundComponent},
 
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes,{useHash: true});