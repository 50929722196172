import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { SharedService } from 'src/app/services/service.index';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pantall-principal',
  templateUrl: './pantall-principal.component.html',
  styleUrls: ['./pantall-principal.component.css']
})
export class PantallPrincipalComponent implements OnInit {
  imagenes: any;

  constructor(public _sharedService: SharedService, public router: Router,  private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.getImagenes();
  }

  newImagen(){

    this.router.navigate(['/imagenPrincipal']);
   }
   reset(){
    window.location.reload();
  }
  deleteImagenes(id : Number){
    let image = {};
    image['id'] = id;
    swal({
      title: 'Borrar Imagen',
      text: "¿Esta seguro que quieres borrar el Borrar Imagen?",
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((ret) => {
      if (ret.value) {
    this._sharedService.serviceDeleteArchivo(image).subscribe(
    resp => {
      if(resp){
        this.reset();
        swal('la imagen se ha borrado', '', 'success');
        
        
      } else {
        console.log("Response error: " + resp);
      }
      },
      err => {     
        swal.close();
        //console.log("Server error: " + err);
        switch(err.status) { 
          default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
        }
      }
    )
    swal(
      'Imagen borrado',
      'Imagen se ha borrado',
      'success'
      );
    }
  })  
}
getImagenes(){
  swal({
    text: 'Cargando...',
    allowOutsideClick: false,
    onBeforeOpen: () => {
      swal.showLoading();
  this._sharedService.serviceImagenesPrincipal().subscribe(

  resp => {
    if(resp){
      swal.close();
      this.imagenes = resp;
    } else {
      console.log("Response error: " + resp);
    }
    },
    err => {     
      swal.close();
      //console.log("Server error: " + err);
      switch(err.status) { 
        case 500: { 
          swal("no hay imagenes", "no hay imagenes", "warning");break; 
        }
        default: swal('Error de Servidor', 'No se ha podido cargar debido a un error interno del servidor.', 'error'); break;
      }
    } 
  );
}
});
  }

}
